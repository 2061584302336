import { useSelector } from "react-redux";
import { useYapilyAuthorizationConsentMutation } from "../../store/Yapily";
import PaymentCard from "../controllers/PaymentCard";

const ConsentPage = ({ onButtonClick, selectedRecipient, amount }) => {
    // const selectedRecipient = useSelector(state => state.transfer.recipient);
    // const [YapilyAuthorizationConsent, { isLoading, isSuccess, isError }] =
    //     useYapilyAuthorizationConsentMutation();

    // const paymentDetails = {
    //     institutionId: "modelo-sandbox",
    //     amount: 100,
    //     currency: "EUR",
    //     payee: {
    //         name: "saif",
    //         address: {
    //             country: "GB"
    //         },
    //         accountIdentifications: [
    //             {
    //                 type: "IBAN",
    //                 identification: "IE64IRCE92050112345678"
    //             }
    //         ]
    //     }
    // };

    // const handleSubmit = async () => {
    //     try {
    //         const response = await YapilyAuthorizationConsent(paymentDetails).unwrap();
    //         console.log("Payment successful:", response);
    //     } catch (error) {
    //         console.error("Payment failed:", error);
    //     }
    // };

    // console.log("Selected Recipient : ", selectedRecipient);

    return (
        <PaymentCard>
            <div className="bg-white rounded-2xl shadow-md p-8 w-[400px]">
                <h2 className="text-2xl flex align-start font-bold mb-3">Review details</h2>
                <p className="text-black text-left font-500  mb-6">
                    Faremit have partnered with Yapily Connect to securely initiate payment from
                    your Bank.
                </p>
                <div className="mb-6">
                    <p className="font-bold text-black text-left mb-2">Payment total</p>
                    <p className="text-xl mb-4 text-left text-black">{`${
                        amount ? amount : "0"
                    } EUR`}</p>
                    <p className="font-bold text-black text-left mb-4 ">Payee details</p>
                    <ul className="list-none text-black-700 text-left list-disc pl-5 font-medium">
                        <li>{`Payee Name: ${selectedRecipient?.firstName}`}</li>
                        <li>
                            {`Account Type: ${selectedRecipient?.bankAccounts[0]?.accountType}`}
                        </li>
                        <li>{`Account Number: ${selectedRecipient?.bankAccounts[0]?.IBAN}`}</li>
                    </ul>
                </div>
                <div className="border border-[#f5f5f5] text-left p-4 rounded mb-6 text-sm text-black">
                    <p className="text-black">
                        By using the service, you agree to{" "}
                        <span className="font-semibold">Yapily Connect</span> initiating this
                        payment and its <span className="font-semibold">Terms & Conditions</span>{" "}
                        and <span className="font-semibold">Privacy Notice</span>.
                    </p>
                </div>
                <button
                    onClick={onButtonClick}
                    className="w-full bg-[#4f46e5] text-[#ffffff] py-3 rounded mb-3 hover:bg-purple-700 hover:text-[#ffffff]"
                >
                    Continue
                </button>
                <button className="w-full text-purple-600 font-bold py-3 rounded hover:bg-gray-200">
                    Cancel
                </button>
            </div>
        </PaymentCard>
    );
};

export default ConsentPage;
